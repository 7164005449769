import { Routes, RouterModule } from '@angular/router';

//  Route for content layout with sidebar, navbar and footer
// loadChildren: () => import('../../pages/content-pages/content-pages.module').then(m => m.ContentPagesModule)
export const FULL_ROUTES: Routes = [
  /*{
    path: 'partner_sanitario',
    loadChildren: () => import('../modules/structure/structure.module').then(m => m.StructureModule)

  },
  {
    path: 'cetegorie',
    loadChildren: () => import('../modules/service_type/service_type.module').then(m => m.ServiceTypeModule)

  },


  {
    path: 'prestazione',
    loadChildren: () => import('../modules/service/service.module').then(m => m.ServiceModule)

  },
  {
    path: 'prestazione_struttura',
    loadChildren: () => import('../modules/structure_service/structure-service.module').then(m => m.StructureServiceModule)

  },
  {
    path: 'operatore_sanitario',
    loadChildren: () => import('../modules/employee/employee.module').then(m => m.EmployeeModule)

  },
  {
    path: 'calendario',
    loadChildren: () => import('../modules/full-calendar/full-calendar.module').then(m => m.CalendarsModule)

  },*/
];
