import {Injector, NgModule} from '@angular/core';




import { DeviceDetectorModule } from 'ngx-device-detector';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';

import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';


import { WINDOW_PROVIDERS } from './shared/services/window.service';
import {LocalStorageService, NgxWebstorageModule, SessionStorageService} from 'ngx-webstorage';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {AlertModule} from './block/alert/alert.module';
import {AuthModule} from './block/auth/auth.module';
import {FormsModule} from '@angular/forms';
import {LoadingService} from './block/interceptor/loading.service';
import {NotificationInterceptor} from './block/interceptor/notification.interceptor';
import {ErrorHandlerInterceptor} from './block/interceptor/errorhandler.interceptor';
import {JhiEventManager} from 'ng-jhipster';
import {AuthInterceptor} from './block/interceptor/auth.interceptor';
import {Principal} from './block/auth/principal.service';
import {AccountService} from './block/auth/account.service';
import {StateStorageService} from './block/auth/state-storage.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AuthGuard} from './block/auth/auth-guard.service';
import {AppComponent} from './app.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {AgmCoreModule} from '@agm/core';
import {AuthService} from './block/auth/auth.service';
import {LayoutModule} from './layouts/layout.module';
import {ToastrModule} from 'ngx-toastr';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import {APP_BASE_HREF} from '@angular/common';




const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/", ".json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AuthModule,
    AlertModule,
    RouterModule,
    LayoutModule,
    FormsModule,
    DeviceDetectorModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(), // ToastrModule added
    NgxWebstorageModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    NgbModule,
    NgxSpinnerModule,
    DeviceDetectorModule.forRoot(),
    RecaptchaV3Module,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({apiKey: 'AIzaSyCzmnPTjb333dN2vVZqfFxFlJZsAXhsNSc', libraries: ['places']}),

    PerfectScrollbarModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    LoadingService,
    AccountService,
    JhiEventManager,
    StateStorageService,
    Principal,
    BsModalService,
    LoadingService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LeQVjQaAAAAAOy5Pa_dUkPJ5OAJMQHm7CHBXpCW' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [
        LocalStorageService,
        SessionStorageService,
        LoadingService
      ]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
      deps: [
        JhiEventManager,
        Injector
      ]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotificationInterceptor,
      multi: true,
      deps: [
        Injector
      ]
    },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    WINDOW_PROVIDERS,

  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
