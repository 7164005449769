import {RouteInfo} from './sidebar.metadata';
import {STRUCTURE, SUPER_ADMIN} from '../../app.constants';

export const ROUTES: RouteInfo[] = [
  {
    path: '/welcome',
    title: 'Dashboard',
    icon: 'ft-home',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    permission: [SUPER_ADMIN,STRUCTURE]
  },
  {
    path: '/partner_sanitario',
    title: 'Partner Sanitario',
    icon: 'ft-folder',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    permission: [SUPER_ADMIN]
  },
  {
    path: '/cetegorie',
    title: 'Categorie',
    icon: 'ft-list',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    permission: [SUPER_ADMIN]
  },
  {
    path: '/prestazione',
    title: 'Prestazioni',
    icon: 'ft-list',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    permission: [SUPER_ADMIN]
  },
  {
    path: '/prestazione_struttura',
    title: 'Prestazioni',
    icon: 'ft-list',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    permission: [STRUCTURE]
  },
  {
    path: '/operatore_sanitario',
    title: 'Operatore Sanitario',
    icon: 'ft-users',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    permission: [STRUCTURE]
  },
  {
    path: '/partner_sanitario/profile/me',
    title: 'Profilo',
    icon: 'ft-folder',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    permission: [STRUCTURE]
  },
  {
    path: '/calendario',
    title: 'Calendario',
    icon: 'ft-calendar',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    permission: [STRUCTURE]
  },

];
